<template>
  <div class="flv-player-wrapper"></div>
</template>

<script>
import DPlayer from 'dplayer'
import axios from 'axios'

export default {
  name: 'flv-player',

  data() {
    return {
      loaded: false,
      mounted: false,
      setVideoUrl: false,
      setSwitchUrl: false,
    }
  },

  props: {
    switchUrl: String,
    videoUrl: String,
    history: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    videoUrl: {
      immediate: true,
      handler(v) {
        console.log(v)
        console.log('this.setSwitchUrl = ' + this.setSwitchUrl)
        if (v && this.setSwitchUrl) {
          this.load()
        }
        this.setVideoUrl = true
      },
    },
    switchUrl: {
      immediate: true,
      handler(v) {
        console.log(v)
        console.log('this.setVideoUrl = ' + this.setVideoUrl)
        if (v && this.setVideoUrl) {
          this.load()
        }
        this.setSwitchUrl = true
      },
    },
  },

  methods: {
    load() {
      console.log('flv-player mounted ? ' + this.mounted)
      console.log('flv-player setSwitchUrl ? ' + this.setSwitchUrl)
      console.log('flv-player setVideoUrl ? ' + this.setVideoUrl)
      if (this.mounted) {
        console.log('flv-player this.player ? ' + this.player)
        if (this.player) {
          this.player.destroy()
        }
        this.switchUrl &&
          axios.get(this.switchUrl).then((data) => {
            if ([1, -85].includes(data.data.code)) {
              /* eslint-disable no-new */
              this.player = new DPlayer({
                container: this.$el,
                screenshot: true,
                live: false,
                mutex: false,
                video: {
                  pic: '/img/videoDefault.png',
                  url: this.videoUrl,
                  type: 'flv',
                },
              })
              this.player.play()
            }
            if (data.data.code === -98) {
              if (!window.msgInstance) {
                window.msgInstance = this.$message({
                  type: 'warning',
                  message: '当前设备实时监控视频离线',
                  onClose: function () {
                    window.msgInstance = null
                  },
                })
              }
              // this.player.destroy()
            }
            // this.setVideoUrl = false
            // this.setSwitchUrl = false
          })
        if (this.history) {
          this.player.on('ended', () => {
            this.$alert('视频已播放完毕！', '提示', {
              confirmButtonText: '确定',
            })
          })
        }
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.mounted = true
      this.load()
    })
  },

  beforeDestroy() {
    this.player && this.player.destroy()
  },
}
</script>

<style lang="scss">
.flv-player-wrapper {
  width: 100%;
  height: 100%;
  .dplayer-video {
    object-fit: fill;
  }
  .dplayer-volume,
  .dplayer-time,
  .dplayer-setting {
    display: none !important;
  }
}
</style>
