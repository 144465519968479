<template>
  <div class="video-view">
    <div class="content-wrapper">
      <div class="video-box">
        <div class="video-item" v-for="v in videoList" :key="v.id" style="height:50%">
          <flv-player :switch-url="v.switchUrl" :video-url="v.videoUrl"></flv-player>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlvPlayer from '@/components/flv-player.vue'
import { restfulUrl, webSocketUrl } from '@/config'
function getQueryString (e) {
  let link = window.location.search.replace(/%/g, '%25')
  let t = new RegExp('(^|&)' + e + '=([^&]*)(&|$)', 'i')
  let n = link.substr(1).match(t)
  return n != null ? unescape(n[2]) : null
}
export default {
  name: 'videolist',
  components: { FlvPlayer },
  data () {
    return {
      videoList: [],
      carid: '015920042039'
    }
  },
  methods: {
    getVideoList () {
      // let carId = p.carDeviceId
      console.log(this.carid)
      const list = []
      for (let i = 1; i <= 8; i++) {
        const param = {
          tdh: i,
          type: 1,
          tid: this.carid
        }
        list.push({
          switchUrl: restfulUrl + `/api.json?func=1003&carid=${this.carid}&tdh=${i}`,
          videoUrl: webSocketUrl + `/websocket?token=&params=${encodeURIComponent(JSON.stringify(param))}`
        })
      }
      this.videoList = list
    }
  },

  mounted () {
    this.carid = getQueryString('id')
    this.getVideoList()
  },

  created () {
  }
}
</script>

<style lang="scss">
.video-view {
  height: 100vh;
.video-box{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .video-item{
      width: 100%;
      height: 50vh;
      video{
        height: 100%;
      }
    }
  }
}
</style>
